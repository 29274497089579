// A major bug exists in Safari 15.4 where attempts to smooth-scroll an element
// causes a silent failure and this persists until Safari 16. Since this is an
// actual browser bug that can't be sniffed for, all we can do is disable smooth
// scrolling for Safari 15.x 2022/09/29
const isSafari15 = !!navigator.userAgent.match(/15[.eE]\d+ Safari/i);
const SMOOTH_SCROLL = isSafari15 ? 'instant' : 'smooth';

// Main page analytics
if (document.getElementById("warframe1999")) {
    // Play Warframe link
    const PlayLink = document.getElementById("masthead-play-link");
    if (PlayLink) {
        PlayLink.addEventListener('click', (e) => {
            e.preventDefault();
            e.stopPropagation();
            document.getElementById("btnSignup").click();
        })
    }

    // Scaldra slideshow
    const ScaldraBackgrounds = document.querySelectorAll('.ScaldraGrid-background');
    const ScaldraPages = document.querySelectorAll('.ScaldraGrid-page');
    const ScaldraCards = document.querySelectorAll('.ScaldraGrid-tile');
    function setActiveScaldraTile(index) {
        ScaldraBackgrounds.forEach((slide, i) => {
            slide.classList.toggle('is-active', index === i);
        });
        ScaldraPages.forEach((page, i) => {
            page.classList.toggle('is-active', index === i);
        });
        ScaldraCards.forEach((tile, i) => {
            tile.classList.toggle('is-active', index === i);
        });
    }
    ScaldraCards.forEach((tile, index) => {
        tile.addEventListener('click', (e) => {
           setActiveScaldraTile(index);
        });
    });
}

const HubLocationsBackgrounds = document.querySelectorAll('.LocationsBackground-slide');
const HubLocationsCards = document.querySelectorAll('.LocationCard');
if (HubLocationsBackgrounds  && HubLocationsBackgrounds.length > 0) {
    function syncBackgrounds(index) {
        HubLocationsBackgrounds.forEach((background, i) => {
            background.classList.toggle('is-active', i === index);
        });
        HubLocationsCards.forEach((card, i) => {
            card.classList.toggle('is-active', i === index);
        });
    }

    HubLocationsCards.forEach((card, index) => {
        card.addEventListener('click', (e) => {
            e.stopPropagation();
            syncBackgrounds(index);
        });
    });
}

const HubEntratiPhone = document.querySelector('#featured-entrati .EntratiPhone');
if (HubEntratiPhone) {
    const endpoint = HubEntratiPhone.getAttribute('data-endpoint');
    const token = HubEntratiPhone.getAttribute('data-token');
    const PhoneAudio = new Audio(HubEntratiPhone.getAttribute('data-audio'));
    PhoneAudio.loop = true;
    let sent = false;

    function hangup() {
        const formData = new FormData();
        formData.append('_token', token);
        fetch(endpoint, {
            method: 'POST',
            body: formData,
        });
    }

    HubEntratiPhone.addEventListener('click', (e) => {
        e.stopPropagation();
        e.preventDefault();

        if (!sent) {
            sent = true;
            hangup();
            HubEntratiPhone.classList.add('is-up');
            syncPhoneAudio();
        }
    });

    function syncPhoneAudio() {
        const rect = HubEntratiPhone.getBoundingClientRect();
        const inRange = rect.bottom > 0 && rect.top < window.innerHeight;
        const shouldPlay = !HubEntratiPhone.classList.contains('is-up') && inRange;
        if (shouldPlay) {
            const centerline = rect.top + rect.height / 2;
            const distance = 0.5 - (Math.abs(window.innerHeight / 2 - centerline) / window.innerHeight);
            PhoneAudio.volume = Math.max(0, distance / 2);
            if (PhoneAudio.paused) PhoneAudio.play();
        }
        else
        {
            if (!PhoneAudio.paused) PhoneAudio.pause();
        }
    }
    PhoneAudio.addEventListener('canplay', () => {
        syncPhoneAudio();
        window.addEventListener('resize', syncPhoneAudio);
        window.addEventListener('scroll', syncPhoneAudio);
        document.addEventListener('click', syncPhoneAudio);
    });
}

const OnLyneGrid = document.querySelector("#on-lyne .MainGrid");
if (OnLyneGrid != null) {
    const OnLyneCharacterCards = OnLyneGrid.querySelectorAll(".CharacterCard");
    const OnLyneCharacterPages = OnLyneGrid.querySelectorAll(".MainGridPage");
    let glitchTimeout;

    function resetCards() {
        ChordCard.classList.remove('is-flipped');
        ChordCard.classList.remove('is-active');
        window.forEachNode(OnLyneCharacterCards, (other) => {
            other.classList.toggle('is-active', false)
            other.classList.toggle('is-inactive', false)
            other.classList.toggle('is-glitchy', false)
            other.classList.toggle('is-glitched', false)
            other.classList.toggle('is-glitched-out', false)
        });
        OnLyneGrid.classList.toggle('is-active', false);
        window.forEachNode(OnLyneCharacterPages, (page) => {
            page.classList.toggle('is-active', page.getAttribute("data-index") === "0");
            page.classList.toggle('is-glitched', false)
            page.classList.toggle('is-glitched-out', false)
        });
    }

    const OnLyneSection = document.querySelector("#on-lyne .MainSection");
    OnLyneSection && OnLyneSection.addEventListener('click', (e) => {
       resetCards();
    });

    const GUITAR_B = new Audio(window.MediaDir+'/guitar-b.mp3');
    const GUITAR_C = new Audio(window.MediaDir+'/guitar-c.mp3');
    const GUITAR_D = new Audio(window.MediaDir+'/guitar-d.mp3');
    const GUITAR_E = new Audio(window.MediaDir+'/guitar-e.mp3');
    const GUITAR_F = new Audio(window.MediaDir+'/guitar-f.mp3');
    const GUITAR_CHORD = new Audio(window.MediaDir+'/guitar-chord.mp3');
    const ChordCard = OnLyneGrid.querySelector('.CallingCard');
    let sequence = 0;
    function playAudio(audio) {
        if (!audio.paused) {
            audio.pause();
            audio.currentTime = 0;
        }
        audio.volume = 0.5;
        audio.play();
    }
    function showChord() {
        playAudio(GUITAR_CHORD);
        ChordCard.classList.remove('is-flipped');
        ChordCard.classList.add('is-active');

        window.forEachNode(OnLyneCharacterCards, (other) => {
            other.classList.toggle('is-active', false)
            other.classList.toggle('is-inactive', true)
            other.classList.toggle('is-glitchy', false)
            other.classList.toggle('is-glitched', false)
            other.classList.toggle('is-glitched-out', false)
        });
        OnLyneGrid.classList.toggle('is-active', true);
        window.forEachNode(OnLyneCharacterPages, (page) => {
            page.classList.toggle('is-active', parseInt(page.getAttribute("data-index")) === 0);
            page.classList.toggle('is-glitched', false)
            page.classList.toggle('is-glitched-out', false)
        });
    }
    ChordCard.addEventListener('click', (e) => {
        e.stopPropagation();
        ChordCard.classList.toggle('is-flipped');
    });
    function triggerGuitar(index) {
        switch (index) {
            case 1:
                if (sequence === 7) showChord();
                else playAudio(GUITAR_B);
                sequence = 0;
                break;
            case 2:
                if (sequence === 0 || sequence === 6) sequence ++;
                else sequence = 0;
                playAudio(GUITAR_C);
                break;
            case 3:
                if (sequence === 1 || sequence === 5) sequence ++;
                else sequence = 0;
                playAudio(GUITAR_D);
                break;
            case 4:
                if (sequence === 2 || sequence === 4) sequence ++;
                else sequence = 0;
                playAudio(GUITAR_E);
                break;
            case 5:
                if (sequence === 3) sequence ++;
                else sequence = 0;
                playAudio(GUITAR_F);
                break;
        }
    }

    window.forEachNode(OnLyneCharacterCards, (card) => {
        const index = parseInt(card.getAttribute("data-index"));
        const linkedPage = OnLyneGrid.querySelector(`.MainGridPage[data-index="${index}"]`);
        card.addEventListener('click', (e) => {
            e.stopPropagation();
            clearTimeout(glitchTimeout);
            ChordCard.classList.remove('is-flipped');
            ChordCard.classList.remove('is-active');
            if (card.classList.contains('is-active')) {
                if (card.classList.contains('is-glitchy')) {
                    e.stopPropagation();
                    card.classList.toggle('is-glitchy', false)
                    card.classList.toggle('is-glitched', true)
                    linkedPage.classList.toggle('is-glitched', true)
                }
                else
                {
                    resetCards();
                }
            } else {
                window.forEachNode(OnLyneCharacterCards, (other) => {
                    other.classList.toggle('is-active', other === card)
                    other.classList.toggle('is-inactive', other !== card)
                    other.classList.toggle('is-glitchy', other === card)
                    other.classList.toggle('is-glitched', false)
                    other.classList.toggle('is-glitched-out', false)
                });
                OnLyneGrid.classList.toggle('is-active', true);
                window.forEachNode(OnLyneCharacterPages, (page) => {
                    page.classList.toggle('is-active', parseInt(page.getAttribute("data-index")) === index);
                    page.classList.toggle('is-glitched', false)
                    page.classList.toggle('is-glitched-out', false)
                });
                triggerGuitar(index);
            }
        });
    });
}

const OnLyneMediaPlayer = document.querySelector("#on-lyne .MediaPlayer");
if (OnLyneMediaPlayer != null) {
    const MediaPlayerTrack = OnLyneMediaPlayer.querySelector(".MediaPlayer-track");
    const MediaPlayerDuration = OnLyneMediaPlayer.querySelector(".MediaPlayer-duration");
    const MediaPlayerPlay = OnLyneMediaPlayer.querySelector(".MediaPlayer-play");
    const MediaPlayerPause = OnLyneMediaPlayer.querySelector(".MediaPlayer-pause");
    const MediaPlayerStop = OnLyneMediaPlayer.querySelector(".MediaPlayer-stop");
    const MediaPlayerEntries = OnLyneMediaPlayer.querySelectorAll(".MediaPlaylist-entry");

    const MediaPlayerAudio = [];
    let currentTrackIndex = 0;

    for(const trackData of window.AudioData) {
        MediaPlayerAudio.push(new Audio(trackData.url));
    }

    function currentAudioTrack() {
        return MediaPlayerAudio[currentTrackIndex];
    }

    function syncPlayerControls() {
        const currentTrack = currentAudioTrack();
        MediaPlayerPlay.classList.toggle('is-pressed', !currentTrack.paused);
        MediaPlayerPause.classList.toggle('is-pressed', currentTrack.paused && currentTrack.currentTime > 0);
    }

    MediaPlayerPlay.addEventListener('click', (e) => {
        e.stopPropagation();
        currentAudioTrack().play();
        syncPlayerControls();
    });
    MediaPlayerPause.addEventListener('click', (e) => {
        e.stopPropagation();
        currentAudioTrack().pause();
        syncPlayerControls();
    });
    MediaPlayerStop.addEventListener('click', (e) => {
        e.stopPropagation();

        const currentTrack = currentAudioTrack();
        if (!currentTrack.paused) currentTrack.pause();
        currentTrack.currentTime = 0;
        syncPlayerControls();
    });

    function formatDuration(time) {
        const minutes = Math.floor(time / 60);
        const seconds = Math.floor(time % 60);
        return {minutes, seconds};
    }

    let track = MediaPlayerTrack.innerText + " ---- ";
    let duration = "0:00";
    let flicker = false;
    MediaPlayerTrack.innerHTML = track;
    setInterval(() => {
        const currentTrack = currentAudioTrack();
        track = track.substring(1) + track.substring(0, 1);
        MediaPlayerTrack.innerHTML = track;
        if (!currentTrack.paused || currentTrack.currentTime !== 0) {
            const {minutes, seconds} = formatDuration(currentTrack.currentTime);
            duration = minutes.toLocaleString('en-US', {minimumIntegerDigits: 2, useGrouping: false}) + ':' + seconds.toLocaleString('en-US', {minimumIntegerDigits: 2, useGrouping: false});
            flicker = false;
        }
        else
        {
            const {minutes, seconds} = formatDuration(currentTrack.duration);
            duration = minutes.toLocaleString('en-US', {minimumIntegerDigits: 2, useGrouping: false}) + ':' + seconds.toLocaleString('en-US', {minimumIntegerDigits: 2, useGrouping: false});
            flicker = Math.floor(Date.now() / 1000) % 2 === 0;
        }
        MediaPlayerDuration.innerHTML = duration;
        MediaPlayerDuration.classList.toggle('is-flicker', flicker);
        syncPlayerControls();
    }, 500);

    MediaPlayerEntries.forEach((entry, index) => {
        entry.addEventListener('click', (e) => {
            e.stopPropagation();
            if (index !== currentTrackIndex) {
                let currentTrack = currentAudioTrack();
                if (!currentTrack.paused) currentTrack.pause();

                const data = window.AudioData[index];
                currentTrackIndex = index;
                currentTrack = currentAudioTrack();
                currentTrack.currentTime = 0;
                track = data.title + " ---- ";
                syncPlayerControls();
                MediaPlayerEntries.forEach((e, i) => e.classList.toggle('is-active', i === index));
            }
            if (currentAudioTrack().paused) {
                currentAudioTrack().play();
            }
        });
    });
}

const MastheadSlideshow = document.querySelector(".MastheadSlideshow");
if (MastheadSlideshow) {
    const images = MastheadSlideshow.querySelectorAll(".MastheadSlideshow-slide");
    const controls = MastheadSlideshow.querySelectorAll(".MastheadSlideshow-control");
    if (images.length !== controls.length) {
        console.error("Error: Slideshow slide and control count mismatch");
    }
    else
    {
        let advanceTimeout;
        let activeSlideIndex = 0;
        const slides = [];

        function swapSlide(index) {
            if (index !== activeSlideIndex) {
                clearTimeout(advanceTimeout);
                const previousSlideIndex = activeSlideIndex;
                activeSlideIndex = index;
                for (let i = 0; i < slides.length; i ++) {
                    const {image, control} = slides[i];
                    image.classList.toggle('is-matte', i === previousSlideIndex);
                    image.classList.toggle('is-active', i === activeSlideIndex);
                    control.classList.toggle('is-active', i === activeSlideIndex);
                }
                advanceTimeout = setTimeout(advanceSlide, 5000);
            }
        }
        function advanceSlide() {
            let index = activeSlideIndex + 1;
            if (index >= slides.length) index = 0;
            swapSlide(index);
        }

        for (let i = 0; i < images.length; i ++) {
            const index = i;
            slides.push({
               image: images[i],
               control: controls[i]
            });
            controls[i].addEventListener('click', (e) => {
               e.stopPropagation();
               swapSlide(index);
            });
        }

        document.addEventListener("DOMContentLoaded", function() {
            advanceTimeout = setTimeout(advanceSlide, 5000);
        });
    }
}

const AppointmentForm = document.querySelector('form.DetailAppointments-form');
if (AppointmentForm) {
    const AppointmentName = AppointmentForm.querySelector('.DetailAppointments-input');
    const AppointmentEngineer = AppointmentForm.querySelector('.DetailAppointments-select');
    const AppointmentSubmit = AppointmentForm.querySelector('.DetailAppointments-submit');
    let isFilled = false;

    const InputErrorAudio = new Audio(window.MediaDir+'/input-error.mp3');
    InputErrorAudio.volume = 0.5;

    function validateForm() {
        isFilled = AppointmentName.value.trim() !== "" && AppointmentEngineer.value !== "null";
        AppointmentSubmit.classList.toggle('is-disabled', !isFilled);
    }
    AppointmentName.addEventListener('keydown', validateForm);
    AppointmentName.addEventListener('change', validateForm);
    AppointmentEngineer.addEventListener('change', validateForm);

    AppointmentForm.addEventListener('submit', (e) => {
        e.stopPropagation();
        e.preventDefault();

        if (isFilled) {
            if (AppointmentEngineer.value === 'mr-rogers') {
                window.open('https://www.youtube.com/watch?v=lrzKT-dFUjE', '_blank').focus();
            }
            else
            {
                if (!InputErrorAudio.paused) InputErrorAudio.pause();
                InputErrorAudio.currentTime = 0;
                InputErrorAudio.play();
            }
        }
    });
}

const AtomicycleNavButtons = document.querySelectorAll("#atomicycle .PageNav-button");
if (AtomicycleNavButtons && AtomicycleNavButtons.length > 0) {
    const AtomicycleBodyPages = document.querySelectorAll("#atomicycle .BodyPage");
    const EntratiNotes = document.querySelectorAll('.EntratiNote');
    const DraggableElements = document.querySelectorAll(".DraggableElement");
    const DraggableReset = document.querySelector('.DraggableReset');
    const draggables = [];

    function syncNavButtons(activeButton) {
        window.forEachNode(AtomicycleNavButtons, (button) => {
            button.classList.toggle('is-active', button === activeButton);
        });
    }
    function syncBodyPages(index) {
        window.forEachNode(AtomicycleBodyPages, (page) => {
            page.classList.toggle('is-active', page.getAttribute("data-index") === index);
        });
    }
    function syncNotes(index) {
        window.forEachNode(EntratiNotes, (note) => {
            if (note.hasAttribute('data-page-index')) {
                note.classList.toggle('is-inactive', note.getAttribute("data-page-index") !== index);
            }
        });
    }

    AtomicycleNavButtons.forEach((button) => {
        if (button.hasAttribute("data-index")) {
            button.addEventListener("click", (e) => {
                syncNavButtons(button);
                syncBodyPages(button.getAttribute("data-index"));
                syncNotes(button.getAttribute("data-index"));
            });
        }
    });

    const FlipAudio = new Audio(window.MediaDir+'/note-flip.mp3');
    FlipAudio.volume = 0.5;
    let flipEnabled = false;

    function enableFlip() {
        if (!flipEnabled) {
            flipEnabled = true;
        }
        EntratiNotes.forEach((note) => {
            note.classList.add('is-flippable');
        });
    }

    EntratiNotes.forEach((note) => {
        note.addEventListener('click', (e) => {
            e.stopPropagation();
            note.classList.toggle('is-active', true);
            enableDraggableReset();
        });

        const FlipAnchor = note.querySelector('.EntratiNote-oull');
        FlipAnchor.addEventListener('click', (e) => {
            if (flipEnabled || note.getAttribute('data-note') === 'detailing1') {
                e.stopPropagation();
                note.classList.toggle('is-flipped');
                note.classList.toggle('is-active', true);
                enableDraggableReset();
                enableFlip();
                FlipAudio.play();
            }
        });
        function occluder(e) {
            if (flipEnabled) e.stopPropagation();
        }
        FlipAnchor.addEventListener('mousedown', occluder);
        FlipAnchor.addEventListener('touchstart', occluder, {passive:false});
    });

    function enableDraggableReset() {
        DraggableReset && DraggableReset.classList.toggle("is-hidden", false);
    }

    window.forEachNode(DraggableElements, (draggable) => {
        let startX, startY, startLeft, startTop;
        let deltaX, deltaY;
        let angle = 0;

        function getClientPos(e) {
            if (e.touches && e.touches.length > 0) {
                return {
                    x: e.touches[0].clientX,
                    y: e.touches[0].clientY
                };
            }

            return {
                x: e.clientX,
                y: e.clientY
            };
        }

        function dragStart(e) {
            e.preventDefault();
            const {x, y} = getClientPos(e);
            startX = x;
            startY = y;
            startLeft = draggable.offsetLeft;
            startTop = draggable.offsetTop;

            document.addEventListener('mousemove', drag);
            document.addEventListener('touchmove', drag, {passive:false});
            document.addEventListener('mouseup', dragStop);
            document.addEventListener('touchend', dragStop, {passive:false});
        }

        function drag(e) {
            e.preventDefault();
            const {x, y} = getClientPos(e);
            deltaX = x - startX;
            deltaY = y - startY;

            draggable.style.left = (startLeft + deltaX) + 'px';
            draggable.style.top = (startTop + deltaY) + 'px';
            draggable.classList.toggle('is-active', true);

            if (e.touches && e.touches.length > 1) {
                const dx = e.touches[1].clientX - e.touches[0].clientX;
                const dy = e.touches[1].clientY - e.touches[0].clientY;
                angle = Math.atan2(dy, dx) * (180 / Math.PI);
                draggable.style.transform = `rotate(${angle}deg)`;
            }
            enableDraggableReset();
        }

        function dragStop(e) {
            e.preventDefault();
            document.removeEventListener('mousemove', drag);
            document.removeEventListener('touchmove', drag);
            document.removeEventListener('mouseup', dragStop);
            document.removeEventListener('touchend', dragStop);
        }

        function rotate(e) {
            if (!draggable.classList.contains('is-active')) return;
            e.preventDefault();
            if (e.deltaY) {
                angle += e.deltaY / 20;
                draggable.style.transform = `rotate(${angle}deg)`;
            }
            DraggableReset && DraggableReset.classList.toggle("is-hidden", false);
        }

        function reset() {
            draggable.style.top = null;
            draggable.style.left = null;

            angle = 0;
            draggable.style.transform = null;
            draggable.classList.toggle('is-active', false);
            draggable.classList.toggle('is-flipped', false);
        }

        draggable.addEventListener('mousedown', dragStart);
        draggable.addEventListener('touchstart', dragStart, {passive:false});
        draggable.addEventListener('wheel', rotate, {passive:false});

        draggables.push({
           element: draggable,
           reset
        });
    });
    if (DraggableReset) {
        DraggableReset.addEventListener('click', (e) => {
           e.stopPropagation();
           e.preventDefault();
           for (const draggable of draggables) {
               draggable.reset();
           }
            DraggableReset.classList.toggle("is-hidden", true);
        });
    }
}

const DossierTiles = document.querySelectorAll(".DossierTile");
if (DossierTiles && DossierTiles.length > 0) {
    const TileData = [];
    let currentTileIndex = -1;

    window.forEachNode(DossierTiles, (tile, tileIndex) => {
        const container = tile.querySelector(".DossierTile-container");
        function toggleTile() {
            tile.classList.toggle('is-flipped');
            tile.classList.toggle('is-unflipped', !tile.classList.contains('is-flipped'));
        }
        container.addEventListener('click', (e) => {
            currentTileIndex = tileIndex;
            if (tile.classList.contains('is-blurred')) {
                scrollToTile(tile, true);
                setTimeout(toggleTile, 250);
            }
            else
            {
                toggleTile();
            }
        });
        TileData.push({
           element: tile,
           container
        });
    });

    const DossierWrapper = document.querySelector(".DossierGrid-tileWrapper");

    function getCenterline() {
        const rect = DossierWrapper.getBoundingClientRect();
        if (PageComponents.isDesktopUp()) {
            return rect.left + rect.width * 0.625;
        }
        return rect.left + rect.width / 2;
    }
    function syncTiles() {
        const centerline = getCenterline();
        let dist = 9999;
        let closest = null;
        window.forEachNode(TileData, ({element, container}) => {
            const rect = container.getBoundingClientRect();
            const midpoint = rect.left + rect.width / 2;
            const dx = Math.abs(centerline - midpoint);

            if (dx < dist) {
                closest = element;
                dist = dx;
            }
        });
        window.forEachNode(TileData, ({element}) => {
            element.classList.toggle('is-blurred', element !== closest);
            if (element !== closest) element.classList.toggle('is-flipped', false);
        });
    }

    function scrollToTile(element, smooth=false) {
        const centerline = getCenterline();
        const rect = element.getBoundingClientRect();
        const midpoint = rect.left + rect.width / 2;
        const delta = midpoint - centerline;
        DossierWrapper.scrollBy({left: delta, behavior: smooth ? 'smooth' : 'instant'});
    }
    function prevTile() {
        if (currentTileIndex > 0) {
            currentTileIndex--;
            scrollToTile(TileData[currentTileIndex].element, true);
        }
    }
    function nextTile() {
        if (currentTileIndex < (TileData.length - 1)) {
            currentTileIndex++;
            scrollToTile(TileData[currentTileIndex].element, true);
        }
    }
    document.querySelector(".DossierGrid-prev").addEventListener('click', prevTile);
    document.querySelector(".DossierGrid-next").addEventListener('click', nextTile);

    document.addEventListener('DOMContentLoaded', () => {
        const arthur = document.querySelector('.DossierTile[data-index="0"]');
        currentTileIndex = 2;
        scrollToTile(arthur);

        syncTiles();
        DossierWrapper.addEventListener('scroll', syncTiles);
        window.addEventListener('resize', syncTiles);
    });
}

const MetroStatic = document.querySelector(".SubwayGrid-static");
if (MetroStatic) {
    setInterval(() => {
        MetroStatic.style.backgroundPositionX = (Math.random() * 100) + "%";
    }, 1000/30);
}
